import * as React from 'react';
import styles from './PlayerSection.module.css'
import {MEMBERS} from './player_data';
import {PlayerSlide} from './PlayerSlide';

export const PlayersSection: React.FC = (props) => {
    return (
        <div className={styles.playerSection}>
            <div className={'page-wrapper center'}>
                <h2 style={{textAlign: 'center'}}>Players</h2>
                <div className={styles.playersContainer}>
                    {MEMBERS.map((member) => <PlayerSlide key={member.name} data={member} />)}
                </div>
            </div>
        </div>
    );
};