import * as React from 'react';
import {useEffect, useState} from 'react';
import styles from './Leaderboard.module.css';

import {
    LeaderboardType,
} from '../../../../utils/leaderboard_const';
import {getGlobalLeaderboard, getMapLeaderboard} from '../../../../firebase/firebase';
import {useTabs} from '../../../../components/tabs/TabsProvider';
import {NetworkState} from '../../../../types/network';
import {
    Leaderboard, MapLeaderboard,
    PlayerLeaderboardEntry,
    PlayerMapLeaderboardEntry,
    TeamLeaderboardEntry
} from '../../../../types/leaderboard';


export interface LiveLeaderboardMask {
    id: string;
    tabName: string;
    global?: boolean;
    title?: string;
    hasCheckpoints?: boolean;
    hasSeed?: boolean;
}

interface LiveLeaderboardProps {
    leaderboardMask: LiveLeaderboardMask;
    playerRowRender: (results: PlayerLeaderboardEntry | PlayerMapLeaderboardEntry) => React.ReactNode;
    teamRowRender: (results: TeamLeaderboardEntry) => React.ReactNode;
}


export const LiveLeaderboard: React.FC<LiveLeaderboardProps> = ({leaderboardMask, playerRowRender, teamRowRender}) => {
    const [mode, setMode] = useState<LeaderboardType>(LeaderboardType.PLAYERS);
    const [leaderboard, setLeaderboard] = useState<Leaderboard<any>>();
    const [networkState, setNetworkState] = useState<NetworkState>()

    const isPlayers = mode === LeaderboardType.PLAYERS;

    useEffect(() => {
        let isMounted = true;
        setNetworkState({})
        async function fetchLeaderboard(): Promise<Leaderboard<any> | undefined> {
            let result;
            try {
                if(leaderboardMask.global) {
                    result = await getGlobalLeaderboard(mode);
                } else {
                    result = await getMapLeaderboard(leaderboardMask.id, mode);
                }
                setNetworkState({loading: false, error: false, errorMessage: undefined});
                return result
            } catch (e) {
                console.error(e);
                setNetworkState({loading: false, error: true, errorMessage: 'Error fetching leaderboard'});
            }

        }
        setNetworkState({loading: true, error: false, errorMessage: undefined});
        fetchLeaderboard().then((result) => {
            if (isMounted) {
                setLeaderboard(result);
            }
        }).catch(console.error);

        return () => {
            isMounted = false;
        }
    }, [mode]);

    const handleSwitch = (newMode: LeaderboardType) => (e: React.MouseEvent) => {
        e.preventDefault();
        if(newMode === mode) {
            return;
        }
        setMode(newMode);
    }

    const getHeaderText = () => {
        if (leaderboard?.hasOwnProperty('seed')) {
            // @ts-ignore
            return `Seed: ${leaderboard.seed}`;
        } else {
            return '-';
        }
    }

    return (
        <div className={styles.leaderboardContainer}>
            <div className={styles.topControls}>
                {leaderboard && <span className={styles.seedText}>{getHeaderText()}</span>}
                {!leaderboard && <span className={styles.seedText}>Loading</span>}
                <div className={styles.switch}>
                    <span onClick={handleSwitch(LeaderboardType.PLAYERS)} className={`${isPlayers ? styles.active : null} clickable`}>{isPlayers ? (
                        <b>Players</b>) : 'Players'}</span>
                    <span> | </span>
                    <span onClick={handleSwitch(LeaderboardType.TEAM)} className={`${!isPlayers ? styles.active : null} clickable`}>{!isPlayers ? (
                        <b>Teams</b>) : 'Teams'}</span>
                </div>
            </div>
            <div className={styles.leaderboardContainer}>

                {leaderboard && mode === LeaderboardType.PLAYERS &&
                    // @ts-ignore
                    leaderboard?.results.map(playerRowRender)}
                {leaderboard && mode === LeaderboardType.TEAM && leaderboard?.results.map(teamRowRender)}
                {networkState?.loading && <p>Loading...</p>}
                {networkState?.error && <p>{networkState.errorMessage}</p>}
                {!networkState?.error &&  !networkState?.loading && !leaderboard && <p>No leaderboard available</p>}
            </div>
        </div>
    );
};
