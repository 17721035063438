import * as React from 'react';
import {Paper} from '../../../../components/paper/Paper';
import {RbSlide} from '../../../home/sections/partner/components/slides/RbSlide';
import {RbSlideSmall} from './slides/RbSlideSmall';

export const HomeSliderSmall: React.FC = (props) => {
    return (
        <Paper classname={`page-wrapper`} color={'#0E121A'} lightColor={'#253042'} pattern={false}>
            <RbSlideSmall/>
        </Paper>
    );
};