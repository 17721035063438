import * as React from 'react';
import styles from './NewsLetterSignup.module.css';

export const NewsLetterSignup: React.FC = (props) => {

    const handleSignup = (e: React.FormEvent) => {
        e.preventDefault()
    }


return (
    <div className={styles.newsLetterSignupContainer}>
        <p>Be part of the family</p>
        <h2>join us for<br/> upcoming news</h2>
        <p>Coming soon!</p>
        {/*<form onSubmit={handleSignup} className={styles.signUpForm}>*/}
        {/*    <input type="email" className={styles.emailInput} />*/}
        {/*    <Button className={styles.signUpButton}>Senden</Button>*/}
        {/*</form>*/}
    </div>
);
}
;