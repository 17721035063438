import React from 'react';
import styles from './LiveLeaderboardSection.module.css';
import {TabsProvider} from '../../../components/tabs/TabsProvider';
import {Tab} from '../../../components/tabs/Tab';
import {LeaderboardTabButton} from './leaderboard/LeaderboardTabButton';
import {
    LiveLeaderboard
} from './leaderboard/LiveLeaderboard';
import {PlayerLeaderboardRow} from './leaderboard/row/PlayerLeaderboardRow';
import {LeaderboardTabIndicator} from './LeaderboardTabIndicator';
import {TeamLeaderboardRow} from './leaderboard/row/TeamLeaderboardRow';
import {MapPlayerLeaderboardRow} from './leaderboard/row/MapPlayerLeaderboardRow';
import {
    PlayerLeaderboardEntry,
    PlayerMapLeaderboardEntry,
    TeamLeaderboardEntry
} from '../../../types/leaderboard';


export const LIVE_LEADERBOARD_SECTION_ID = 'live-leaderboard'

export const LiveLeaderboardSection: React.FC = () => {
    const defaultVal = LEADERBOARD_MASK[0];
    // Component implementation
    return (
        <div className={styles.container}>
            <div id={LIVE_LEADERBOARD_SECTION_ID} className={`page-wrapper center ${styles.liveLeaderboardSection}`}>
                <p className={styles.peepoSprintText}>peepoSprint</p>
                <h2 className={'center'}>Leaderboard</h2>
                <TabsProvider defaultVal={defaultVal.id} defaultMeta={defaultVal.tabName}>
                    <LeaderboardTabIndicator />
                    <div className={styles.contentContainer}>
                        <div className={styles.sideMapButtons}>
                            {LEADERBOARD_MASK.map(leaderboard => <LeaderboardTabButton key={leaderboard.id} title={leaderboard.tabName}
                                                                                       value={leaderboard.id}/>)}
                        </div>
                        <div className={styles.leaderboardContainer}>
                            {LEADERBOARD_MASK.map(leaderboard => <Tab key={leaderboard.tabName} value={leaderboard.id}><LiveLeaderboard
                                leaderboardMask={leaderboard} playerRowRender={leaderboard.playerRowRender} teamRowRender={leaderboard.teamRowRender}
                                /></Tab>)}
                        </div>
                    </div>
                </TabsProvider>
            </div>
        </div>
    );
};


export const LEADERBOARD_MASK = [
    {
        id: 'general',
        tabName: 'General',
        global: true,
        teamRowRender: (row: TeamLeaderboardEntry) => (<TeamLeaderboardRow row={row}/>),
        playerRowRender: (row: PlayerMapLeaderboardEntry | PlayerLeaderboardEntry) => (<PlayerLeaderboardRow row={row}/>),
    },
    {
        id: '1',
        tabName: 'Map 01',
        teamRowRender: (row: TeamLeaderboardEntry) => (<TeamLeaderboardRow row={row}/>),
        playerRowRender: (row: PlayerMapLeaderboardEntry |PlayerLeaderboardEntry) => (<MapPlayerLeaderboardRow row={row}/>),
    }, {
        id: '2',
        tabName: 'Map 02',
        teamRowRender: (row: TeamLeaderboardEntry) => (<TeamLeaderboardRow row={row}/>),
        playerRowRender: (row: PlayerMapLeaderboardEntry |PlayerLeaderboardEntry) => (<MapPlayerLeaderboardRow row={row}/>),
    }, {
        id: '3',
        tabName: 'Map 03',
        teamRowRender: (row: TeamLeaderboardEntry) => (<TeamLeaderboardRow row={row}/>),
        playerRowRender: (row: PlayerMapLeaderboardEntry |PlayerLeaderboardEntry) => (<MapPlayerLeaderboardRow row={row}/>),
    }, {
        id: '4',
        tabName: 'Map 04',
        teamRowRender: (row: TeamLeaderboardEntry) => (<TeamLeaderboardRow row={row}/>),
        playerRowRender: (row: PlayerMapLeaderboardEntry |PlayerLeaderboardEntry) => (<MapPlayerLeaderboardRow row={row}/>),
    }, {
        id: '5',
        tabName: 'Map 05',
        teamRowRender: (row: TeamLeaderboardEntry) => (<TeamLeaderboardRow row={row}/>),
        playerRowRender: (row: PlayerMapLeaderboardEntry |PlayerLeaderboardEntry) => (<MapPlayerLeaderboardRow row={row}/>),
    }
];
