import * as React from 'react';
import styles from './LiveStreamPaper.module.css';
import {Paper} from '../../../../../components/paper/Paper';
import Button from '../../../../../components/button/Button';
import {TwitchStream} from '../../../../../components/twitch/TwitchStream';
import {NavLink} from 'react-router-dom';
import {INFORMATION_ROUTE, LIVE_EVENT_ROUTE} from '../../../../../router/routerUrls';

export const LiveStreamPaper: React.FC = (props) => {
    return (
        <Paper classname={`page-wrapper`}>
            <div className={styles.contentContainer}>
                <h2>The invitational<br/>only Tournament</h2>
                <p>Wir sind ein innovatives Speedrun-Turnier mit Einladung im deutschsprachigen Raum. Dabei vereinen
                wir Entertainment und qualitativen E-Sports in einem Tag-Team Format.</p>
                <div className={styles.buttonContainer}>
                    <NavLink to={INFORMATION_ROUTE}>
                        <Button>INFORMATIONEN</Button>
                    </NavLink>
                    <NavLink to={LIVE_EVENT_ROUTE}>
                        <Button color={'secondary'}>LIVE EVENT</Button>
                    </NavLink>
                </div>
            </div>

            <TwitchStream width={600} height={400} className={styles.playerContainer}/>

        </Paper>
    );
};


