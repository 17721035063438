// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {doc, getDoc, getFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import {
    LeaderboardType
} from '../utils/leaderboard_const';
import {MapPlayerLeaderboard, MapTeamLeaderboard, PlayerLeaderboard, TeamLeaderboard} from '../types/leaderboard';


const firebaseConfig = {
    apiKey: 'AIzaSyALeFlpBdq3EyCyvSdFUOahJI2Zlk5g1yo',
    authDomain: 'odyssey-442a0.firebaseapp.com',
    projectId: 'odyssey-442a0',
    storageBucket: 'odyssey-442a0.appspot.com',
    messagingSenderId: '118761131539',
    appId: '1:118761131539:web:919bb07498b4b0e17439e6',
    measurementId: 'G-JQJR4X987S'
};

const VERSION = 'version8'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

if (isLocalhost()) {
    console.log('Using local firestore emulator');
    connectFirestoreEmulator(db, 'localhost', 8080);
}

function isLocalhost() {
    const hostname = window.location.hostname;
    return (hostname === 'localhost' || hostname === '127.0.0.1');
}


export async function getMapLeaderboard(mapId: string | number, type: LeaderboardType) {
    const prefix = type === LeaderboardType.TEAM ? 'team_' : '';
    const docRef = doc(getFirestore(), 'peepoSprint', VERSION, 'leaderboards', `leaderboard_${prefix}${mapId}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());
        return docSnap.data() as MapPlayerLeaderboard | MapTeamLeaderboard;
    } else {
        // docSnap.data() will be undefined in this case
        console.log('No such document!');
    }

}

export async function getGlobalLeaderboard(type: LeaderboardType) {
    const prefix = type === LeaderboardType.TEAM ? 'team' : 'player';
    const docRef = doc(getFirestore(), 'peepoSprint', VERSION, 'leaderboards', `leaderboard_${prefix}_global`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data() as TeamLeaderboard | PlayerLeaderboard;
    } else {
        // docSnap.data() will be undefined in this case
        console.log('No such document!');
    }

}

export const fetchLeaderboardDoc = async <T>(leaderboardId: string) => {
    const docId = `leaderboard_${leaderboardId}`;
    const docRef = doc(getFirestore(), 'peepoSprint', VERSION, 'leaderboards', docId);
    const leaderboardDoc = await getDoc(docRef);
    if (!leaderboardDoc.exists) {
        throw new Error(`Could not find a leaderboard with id ${docId}`);
    }
    return leaderboardDoc.data() as unknown as T;
};
