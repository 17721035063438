import * as React from 'react';
import {IntroSection} from './intro/IntroSection';
import {RulesSection} from './rules/RulesSection';
import {FaqSection} from './faq/FaqSection';
import {BlockTransition} from '../../components/transition/BlockTransition';
import {NavbarPlaceholder} from '../../components/navbar/NavbarPlaceholder';

export const InformationPage: React.FC = (props) => {
    return (
        <React.Fragment>
            <BlockTransition align={'bottom'} orientation={'up'}>
                <NavbarPlaceholder/>
                <IntroSection/>
            </BlockTransition>

            <RulesSection/>
            <BlockTransition align={'top'} orientation={'down'}>
                <FaqSection/>
            </BlockTransition>

        </React.Fragment>
    );
};