import React from 'react';
import styles from './IntroductionPaper.module.css';
import {MonsterPaper} from '../../../../components/paper/MonsterPaper';
import {NavLink} from 'react-router-dom';
import {COMING_SOON_ROUTE, INFORMATION_ROUTE} from '../../../../router/routerUrls';
import Button from '../../../../components/button/Button';

interface IntroductionPaperProps {
    // Props definition
}

export const IntroductionPaper: React.FC<IntroductionPaperProps> = ({}) => {
    // Component implementation
    return (
        <MonsterPaper>
            <h2>Introduction</h2>
            <p className={styles.textBlock}>Das peepoSprint Speedrun Invitational ist das erste Turnier seiner Art
                im deutschsprachigen Raum! Doch was ist dieses peepoSprint überhaupt?</p>
            <p className={styles.textBlock}>Lade dir jetzt die Mod herunter und genieße das gleiche Spielerlebnis wie deine Favoriten von peepoSprint!</p>
            <div className={styles.buttonContainer}>
                <NavLink to={INFORMATION_ROUTE}>
                    <Button>DOWNLOAD (Soon)</Button>
                </NavLink>
                <NavLink to={COMING_SOON_ROUTE}>
                    <Button color={'secondary'}>LIVE EVENT</Button>
                </NavLink>
            </div>
        </MonsterPaper>
    );
};
