import React, {useEffect, useState} from 'react';
import styles from './BlockTransition.module.css';
import Block1 from '../../assets/website/layout/sq_1.svg';
import Block2 from '../../assets/website/layout/sq_1.svg';
import Block3 from '../../assets/website/layout/sq_1.svg';
import {useParallax} from 'react-scroll-parallax';
import {useIsSmallScreen} from '../../hooks/useIsSmallScreen';

interface BlockTransitionProps {
    align: 'top' | 'bottom';
    orientation: 'up' | 'down';
    children: React.ReactNode;
}

const BLOCK_IMGAES = [Block1, Block2, Block3];
const blockWidth = 1440; // Adjust as needed
const blockHeight = 65; // Adjust as needed
export const BlockTransition: React.FC<BlockTransitionProps> = ({align, orientation, children}) => {
    // Component implementation
    const rotation = orientation.toLocaleLowerCase() === 'up' ? '180deg' : '0deg';
    const transform = align.toLocaleLowerCase() === 'top' ? 'top' : 'bottom';
    const {isSmallScreen}  = useIsSmallScreen();
    const {ref} = useParallax({speed: 0});
    const [blockCount, setBlockCount] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;

            const maxBlockCount = Math.floor(screenWidth / blockWidth) + 1;
            setBlockCount(maxBlockCount);
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const blocks = [];
    for (let i = 0; i <= blockCount; i++) {
        const randomBlock = BLOCK_IMGAES[Math.floor(Math.random() * BLOCK_IMGAES.length)];
        blocks.push(randomBlock);
    }

    return (

        <>
            {/*
            // @ts-ignore */}
            <div className={styles.container} ref={ref} aria-hidden="true">
                <div className={styles.blockContainer}
                     style={{['--rotateVal' as any]: rotation, [transform]: (blockHeight * -1 / (isSmallScreen ? 1.8 : 2.8))}}>
                    {blocks.map((block) => (
                        <img src={block} className={styles.block} alt={'Transition element'} style={{
                            ['--blockWidth' as any]: blockWidth,
                            ['--blockHeight' as any]: blockHeight
                        }}/>))}
                </div>
                {children}
            </div>
        </>
    );
};
