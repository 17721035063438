import React from 'react';
import styles from './SmallScreenPage.module.css';
import {CountdownSection} from '../../home/sections/countdown/CountdownSection';
import {SmallNavbar} from './SmallNavbar';
import {BlockTransition} from '../../../components/transition/BlockTransition';
import {LiveLeaderboardSection} from '../../live_event/leaderboard/LiveLeaderboardSection';
import {SmallStreamSection} from './stream/SmallStreamSection';
import {Footer} from '../../../components/footer/Footer';
import {PartnerContainer} from '../../../components/partners/PartnerContainer';
import {HomeSliderSmall} from './slide/HomeSliderSmall';

interface SmallScreenPageProps {
    // Props definition
}

export const SmallScreenPage: React.FC<SmallScreenPageProps> = ({}) => {
    // Component implementation
    return (
        <div className={styles.container}>

            <BlockTransition align={'bottom'} orientation={'up'}>
                <CountdownSection/>
            </BlockTransition>
            <SmallStreamSection/>
            <BlockTransition align={'top'} orientation={'down'}>
                <LiveLeaderboardSection/>
            </BlockTransition>
            <BlockTransition align={'top'} orientation={'up'}>
                <HomeSliderSmall />
                <PartnerContainer />
            </BlockTransition>
        </div>
    );
};
