import * as React from 'react';
import styles from './Countdown.module.css';
import {TimePart} from './TimePart';
import {useEffect, useState} from 'react';
import {getRemainingTime} from '../../../../../utils/timing';

type CountdownProps = {
    targetDate: Date;
}

export const Countdown: React.FC<CountdownProps> = ({targetDate}) => {
    const [remainingTime, setRemainingTime] = useState(getRemainingTime(targetDate));

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const difference = targetDate.getTime() - currentTime;

            if (difference <= 0) {
                clearInterval(interval);
                return;
            }

            setRemainingTime(getRemainingTime(targetDate));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [targetDate]);


    return (
        <div className={styles.countdownContainer}>
            <span className={styles.spacedText}><b>PEEPOSPRINT</b> STARTS IN</span>
            <div className={styles.timeContainer}>
                <TimePart time={remainingTime.days} label={'days'} />
                <span className={styles.separator}>:</span>
                <TimePart time={remainingTime.hours} label={'hours'} />
               <span className={`${styles.separator}`}>:</span>

                <TimePart time={remainingTime.minutes} label={'minutes'} />
                <span className={styles.separator}>:</span>
                <TimePart time={remainingTime.seconds} label={'seconds'} />
            </div>
        </div>
    );
};
