import React, { useEffect, useState } from 'react';
import styles from './Slider.module.css';

type SliderProps = {
    children?: React.ReactNode;
    className?: string;
};

export const Slider: React.FC<SliderProps> = ({ children, className }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) =>
                prevSlide === React.Children.count(children) - 1 ? 0 : prevSlide + 1
            );
        }, 20000000);

        return () => {
            clearInterval(interval);
        };
    }, [children]);

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? React.Children.count(children) - 1 : prevSlide - 1
        );
    };

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === React.Children.count(children) - 1 ? 0 : prevSlide + 1
        );
    };

    return (
        <div className={`${styles.slider} ${className}`}>
            <div className={styles.sliderControls}>
                <button className={`${styles.sliderControl} ${styles.sliderPrev}`} onClick={handlePrevSlide}></button>
                <button className={`${styles.sliderControl} ${styles.sliderNext}`} onClick={handleNextSlide}></button>
            </div>
            <div className={styles.sliderIndicators}>
                {React.Children.map(children, (_, index) => (
                    <span
                        key={index}
                        className={`${styles.sliderIndicator} ${
                            index === currentSlide ? `${styles.active}` : ''
                        }`}
                    />
                ))}
            </div>
            <div
                className={styles.slidesContainer}
                style={{
                    transform: `translateX(-${currentSlide * 100}%)`,
                }}
            >
                {React.Children.map(children, (child, index) => (
                    <div
                        key={index}
                        className={`${styles.slide} ${
                            index === currentSlide ? `${styles.active}` : ''
                        }`}
                    >
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
};
