import * as React from 'react';
import {Avatar} from './avatar/Avatar';
import styles from './GlobalLeaderboardRow.module.css';
import {TeamLeaderboardEntry} from '../../../../../types/leaderboard';
import {getPlayerImage, getTeam} from '../../../../../utils/firebase_consts';
import {getTime} from '../../../../../utils/timing';


interface GlobalLeaderboardRowProps {
    data: TeamLeaderboardEntry;
}

export const GlobalLeaderboardRow: React.FC<GlobalLeaderboardRowProps> = ({data}) => {

    const {rank, team, ticks, rankingPoints} = data;
    const teamData = getTeam(team);
    const timeString = ticks > 1 ? getTime(ticks) : '-';
    const points = String(rankingPoints).padStart(2, '0');
    return (
        <div className={styles.leaderboardRow}>

            <h3 className={styles.rankText}>{rank}</h3>
            <div className={styles.profileContainer}>
                <Avatar size={64} distance={5} image1={getPlayerImage(teamData.members[0])} image2={getPlayerImage(teamData.members[1])}/>
                <div className={styles.nameContainer}>
                    <h5 style={{color: '#FFB74B'}}>{teamData.name}</h5>
                    {teamData.members && teamData.members.map((member) =>
                        <h4 key={member} style={{color: '#B5B5B5'}}>{member}</h4>)
                    }
                </div>
            </div>
            <div className={styles.pointsContainer}>
                <p style={{color: '#B5B5B5'}}>{timeString}</p>
                <p style={{color: '#10D2C4'}}>{points} P</p>
            </div>
            {/*<div className={styles.emblemContainer}>*/}
            {/*    <p>B</p>*/}
            {/*    <p>B</p>*/}
            {/*    <p>B</p>*/}
            {/*</div>*/}

        </div>
    );
};