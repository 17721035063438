import * as React from 'react';
import styles from './HomeSliderSection.module.css';
import {NewsLetterSignup} from './components/NewsLetterSignup';
import {HomeSlider} from './components/HomeSlider';
import {PartnerContainer} from '../../../../components/partners/PartnerContainer';


export const HomeSliderSection: React.FC = (props) => {
    return (
        <div className={'section ' + styles.homeSliderSection} >
            <NewsLetterSignup />
            <div className={styles.homeSliderMarginTop} id={'rbh'}>
                <div id={'rbh'} style={{display: 'none'}}></div>
                <HomeSlider />
            </div>
            <PartnerContainer/>
        </div>
    );
};