export const RULE_TYPES = [{
    title: 'General',
    value: 'general',
    slides: [{
        title: 'Philosophie',
        description: 'Das Turnier steht im Zeichen des Entertainmentfaktors. Es wurde sorgfältig balanciert und organisiert, um Spaß für TeilnehmerInnen und ZuschauerInnen zu gewährleisten. Fairness und Sportsgeist sind unsere höchsten Prinzipien. Respektloses Verhalten, Regelverstöße zur Vorteilserlangung oder Missachtung der Organisation können zu Konsequenzen wie Punktabzug, Ausschluss oder Verlust des Preisgeldes führen.'
    }, {
        title: 'Regelverstoß',
        description: 'Regelverstöße und technische Probleme werden demokratisch von der Projektorganisation behandelt. Maßnahmen werden intern abgestimmt und bekannt gegeben. Bei längeren Untersuchungen können nach dem Event noch Punkte oder Platzierungen angepasst werden, um den Livestream-Charakter nicht zu beeinträchtigen.'
    }, {
        title: 'Kommunikation',
        description: 'Die Organisation teilt den TeilnehmerInnen im Voraus (über Discord) alle benötigten Informationen und Ressourcen (wie Programme, Mods usw.) mit, um einen reibungslosen Ablauf sicherzustellen. Es wird erwartet, dass alle TeilnehmerInnen diesen Anweisungen folgen und ihre Setups bereits 48h vor beginn aufgesetzt haben.'
    }, {
        title: 'Fragen',
        description: 'TeilnehmerInnen sollten bei Unklarheiten die internen Discord-Channels nutzen, um Fragen zu stellen und Klarheit zu erhalten. Bei Verstoß gegen diese Vorgabe, ob bewusst oder unbeabsichtigt, erfolgt eine Strafe. Dies gilt auch für Grauzonen.'
    },{
        title: 'Zeitmessung',
        description: 'Die Zeitmessung erfolgt mithilfe einer Mod. Es gibt eine maximale Echtzeitgrenze von 30 Minuten, die nicht überschritten werden darf. Für das Turnier wird die Spielzeit gewertet, wobei Ladebildschirme und das ESC-Menü von der Gesamtspielzeit abgezogen werden.'
    },{
        title: 'Livestream',
        description: 'Während des Events müssen alle TeilnehmerInnen auf ihrem Twitch- oder YouTube-Kanal live sein. Es ist nicht erlaubt, Koordinaten oder andere Spielbereiche zu zensieren, außer für Facecam/Handcam und Sponsoren usw. Das Streaming darf ohne Verzögerung (Delay) erfolgen. Zusätzlich muss das Spiel während des gesamten Laufs (vom Seed-Eintrag bis zum Verlassen der Karte) kontinuierlich im Stream sichtbar sein.'
    }]
}, {
    title: 'Game',
    value: 'game',
    slides: [{
        title: 'Client',
        description: 'Als Minecraft-Client wird ein FabricMC-Mod-Setup verwendet. Die "peepoSprint"-Mod wird mithilfe eines Installers zusammen mit Fabric eingerichtet. Die Turniermod ist mit den "peepoSprint"-Servern verbunden und überträgt Installations- und Ingame-Daten.'
    }, {
        title: 'Mods',
        description: 'Das Turnier findet ausschließlich in der Minecraft-Version 1.16.1 statt. Jegliche Modifikationen, die nicht im Spiel selbst vorgenommen werden können, sind verboten, es sei denn die Organisation hat anders kommuniziert. Beispiele dafür sind OptiFine, Fullbright, BLC usw.'
    }, {
        title: 'Difficulty',
        description: 'Die Schwierigkeitsstufe muss vor der Generierung der Welt auf "Easy" gestellt werden. Während des Laufs ist es erlaubt, die Schwierigkeitsstufe jederzeit zu ändern, aber der Wechsel zu "Peaceful" ist nicht gestattet.'
    }, {
        title: 'Settings',
        description: 'Die "options.txt"-Datei darf nicht bearbeitet werden, außer um den Gamma-Wert auf 5.0 einzustellen. Die Verwendung von Ressourcenpaketen ist untersagt. Das Vanilla-Minecraft-Ressourcenpaket muss verwenden werden.'
    }]
}, {
    title: 'Mobs',
    value: 'mobs',
    slides: [{
        title: 'Enderdrache',
        description: 'Die peepoSprint-Mod erlaubt es, einen "Dragon Perch" zu fokussieren. Hierzu muss die Z-Koordinate größer als 60 oder kleiner als -60 sein, und der Drachenkampf muss mindestens 5 Sekunden andauern. "Zero Cycles" bleiben weiterhin möglich.'
    }, {
        title: 'Piglin Trades',
        description: 'Die Mod passt die Handelsraten der Piglins an. Dabei ist sichergestellt, dass bei jedem 7. Handel ein Obsidian, bei jedem 13. Handel 10 Fäden, bei jedem 20. Handel 4 Enderperlen und bei jedem 33. Handel ein Trank mit Feuerresistenz-Effekt fallengelassen wird.'
    }, {
        title: 'Iron Golem',
        description: 'Ein Eisengolem lässt immer 5 Eisen fallen.'
    }, {
        title: 'Blaze Rods',
        description: 'Die Blaze-Rod-Droprate wurde überarbeitet. Jetzt fällt bei jeder Blaze mindestens eine Blaze Rod, falls die vorherige Blaze keine gedroppt hat. Looting-Verzauberungen wirken weiterhin.'
    }, {
        title: 'Gravel',
        description: 'Bei Gravel ist sicher, dass jeder dritte Gravel mindestens einen Flint fallen lässt. Verzauberungen haben keinen Einfluss darauf.'
    }]
}, {
    title: 'Points',
    value: 'points',
    slides: [{
        title: 'Punkte Wertung',
        description: 'Ein Spieler erhält Gesamtpunkte basierend auf den in Checkpoints gesammelten Punkten sowie Bonuspunkten für die Platzierung im Leaderboard, sofern der Lauf erfolgreich abgeschlossen wurde. Die detaillierte Aufteilung ist unter den Abschnitten für Checkpoint- und Bonuspunkte zu finden.'
    }, {
        title: 'Checkpoints',
        description: 'Insgesamt gibt es 4 Checkpoints: Start, Nether In, Ende In und Finished. Jeder Checkpoint enthält Fortschrittspunkte. Sobald der nächste Checkpoint erreicht wird, gilt der vorherige automatisch als erfüllt und vergibt alle Punkte, selbst wenn nicht alle Fortschrittspunkte gesammelt wurden. Im Nether werden 6 Blaze Rods und 12 Enderperlen gesammelt. Im Ende werden die Punkte basierend auf der Drachengesundheit berechnet.'
    }, {
        title: 'Bonuspunkte',
        description: 'Die Punkte werden ungleich zwischen Spielern aufgeteilt, die eine Map in bestimmter Zeit abschließen. Ein abnehmender Grenzbetrag wird mit folgender Formel verwendet:' +
            '(X / (CFG * (CFG + 1) / 2)) * ((CFG + 1) - (i + 1))' +
            'Hierbei steht X für den gesamten Bonuspunkte-Pool, CFG für die Anzahl der erfolgreich abschließenden Spieler und i für den Rang des jeweiligen Spielers im Leaderboard.'
    }, {
        title: 'Map Leaderboard',
        description: 'Das Map-Leaderboard zeigt die individuellen Läufe der Spieler. Für jeden Run wird ein separates Leaderboard mit Zwischenergebnissen erstellt. Dieses enthält Live-Ergebnisse und aktualisiert sich kontinuierlich mit den neuen Spielerdaten. Je nach Modus, wie beispielsweise "Tag Team", können Erweiterungen hinzukommen.'
    }, {
        title: 'Globales Leaderboard',
        description: 'Das Globale Leaderboard bestimmt den Turniergewinner. Es summiert die Ergebnisse der Map-Leaderboards abhängig vom Modus. Dieses Leaderboard wird kontinuierlich mit den aktuellen Daten der Teilnehmenden aktualisiert.'
    }, {
        title: 'Map Punkte Schlüssel',
        description: 'Points = Checkpoint Points + Bonus Points, Betreten des Nethers = 10Pkt mit bis zu 24 Punkte durch das Sammeln von Blaze Rods, Ender Perlen und Ender Augen. Betreten des Ends = 44Pkt, wobei es 20Pkt (Missing Dragon Health/10) gibt. Finish = 64Pkt, zuzüglich Bonuspunkte basierend auf der Platzierung.'
    }]
},]
