import * as React from 'react';
import {NavbarPlaceholder} from '../../components/navbar/NavbarPlaceholder';
import styles from './Privacy.module.css';

export const Privacy: React.FC = (props) => {
    return (
        <>
            <NavbarPlaceholder/>
            <div className={'page-wrapper center'}>
                <div className={styles.container}>
                    <h2>Datenschutzerklärung</h2>
                    <p>In folgender Datenschutzerklärung informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage der gesetzlichen Bestimmungen (Datenschutzgrundverordnung, Telekommunikationsgesetz 2003).</p>
                    <p>Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese besuchen wird Ihre IP-Adresse, Beginn sowie Beginn und Ende der Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar.</p>

                    <h3>Kontakt mit uns</h3>
                    <p>Wenn Sie uns, entweder über unser Kontaktformular auf unserer Webseite, oder per Email kontaktieren, dann werden die von Ihnen an uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den Fall von weiteren Anschlussfragen für sechs Monate bei uns gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe Ihrer übermittelten Daten.</p>

                    <h3>Cookies</h3>
                    <p>Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.</p>

                    <h3>Server-Log Files</h3>
                    <p>Diese Webseite und der damit verbundene Provider erhebt im Zuge der Webseitennutzung automatisch Informationen im Rahmen sogenannter „Server-Log Files“. Dies betrifft insbesondere:</p>
                    <ul>
                        <li>IP-Adresse oder Hostname</li>
                        <li>den verwendeten Browser</li>
                        <li>Aufenthaltsdauer auf der Webseite sowie Datum und Uhrzeit</li>
                        <li>aufgerufene Seiten der Webseite Spracheinstellungen und Betriebssystem</li>
                        <li>„Leaving-Page“ (auf welcher URL hat der Benutzer die Webseite verlassen)</li>
                        <li>ISP (Internet Service Provider)</li>
                    </ul>

                    <h3>Firebase</h3>
                    <p>Unsere Website verwendet Technologien von Firebase, einem Produkt von Google. Firebase ermöglicht uns, verschiedene Funktionen und Dienste für unsere Webseite bereitzustellen. Die Verwendung von Firebase ist technisch notwendig, um Ihnen bestimmte Funktionen unserer Webseite anzubieten und stellt somit ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Durch die Nutzung von Firebase können Daten an Server von Google übertragen werden. Weitere Informationen zur Datenverarbeitung durch Firebase und Google finden Sie in der Datenschutzerklärung von Google unter <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>

                    <h3>Cloudflare Pages</h3>
                    <p>Unsere Webseite wird über Cloudflare Pages gehostet. Cloudflare Pages ist ein Dienst von Cloudflare, Inc. Bei jedem Zugriff auf unsere Webseite werden durch Cloudflare Pages Zugriffsdaten gespeichert. Dies dient der Sicherheit und Performance-Optimierung unserer Webseite. Die Verarbeitung dieser Daten stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar, da die Funktionalität und Sicherheit unserer Webseite von diesem Dienst abhängt. Weitere Informationen zur Datenverarbeitung durch Cloudflare können Sie der Datenschutzerklärung von Cloudflare unter <a href="https://www.cloudflare.com/privacypolicy/">https://www.cloudflare.com/privacypolicy/</a> entnehmen.</p>

                    <h3>Twitch Livestream-Integration</h3>
                    <p>Für die Liveübertragung von Events binden wir den Twitch-Player auf unserer Webseite ein. Dies ermöglicht es uns, Ihnen direkt auf unserer Seite Livestream-Inhalte anzubieten. Die Einbindung des Twitch-Players stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar, da dies für die Bereitstellung und Funktionalität unseres Livestream-Angebots notwendig ist. Durch die Nutzung des Twitch-Players können Daten an Twitch übertragen werden. Weitere Informationen zur Datenverarbeitung durch Twitch können Sie der Datenschutzerklärung von Twitch unter <a href="https://www.twitch.tv/p/en/legal/privacy-notice/">https://www.twitch.tv/p/en/legal/privacy-notice/</a> entnehmen.</p>

                    <h3>Red Bull Highlight</h3>
                    <p>Zum Zwecke der Preiszusendung werden die eingesendeten Daten an die RedBull GmbH weitergegeben, wenn sie dem zustimmen.</p>

                    <p>Diese erhobenen Informationen werden nicht personenbezogen verarbeitet oder mit personenbezogenen Daten in Verbindung gebracht.</p>
                    <p>Der Webseitenbetreiber behält es sich vor, im Falle von Bekanntwerden rechtswidriger Tätigkeiten, diese Daten auszuwerten oder zu überprüfen.</p>

                    <h3>Ihre Rechte als Betroffener</h3>
                    <p>Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns gespeichert sind grundsätzlich ein Recht auf:</p>
                    <ul>
                        <li>Auskunft</li>
                        <li>Löschung der Daten</li>
                        <li>Berichtigung der Daten</li>
                        <li>Übertragbarkeit der Daten</li>
                        <li>Wiederruf und Widerspruch zur Datenverarbeitung</li>
                        <li>Einschränkung</li>
                    </ul>
                    <p>Wenn sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten Verstöße gegen das Datenschutzrecht passiert sind, so haben Sie die Möglichkeit sich bei uns (business@venicraft.at) oder der Datenschutzbehörde zu beschweren.</p>

                    <h3>Sie erreichen uns unter folgenden Kontaktdaten:</h3>
                    <p>Webseitenbetreiber: Rafael Eisler</p>
                    <p>Email: business@venicraft.at</p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <p>Quelle: <b><a href="https://fairesrecht.at/anwalt/ort/Wien/1">Rechtsanwalt finden Wien</a></b> in Kooperation mit <b><a href="https://fairesspiel.at/">Geld zurück</a></b></p>

                </div>

            </div>
        </>
    );
};