import {getPlayerImage} from '../../../utils/firebase_consts';

export const MEMBERS = [{
    name: 'Wichtiger',
    team: 0,
    avatar: getPlayerImage('wichtiger'),
    twitter: 'https://twitter.com/WichtigerYT',
    twitch: 'https://twitch.tv/Wichtiger',
    youtube: 'https://youtube.com/Wichtiger'
}, {
    name: 'NiceTwice',
    team: 0,
    avatar: getPlayerImage('nicetwice'),
    insta: '',
    twitch: 'https://www.twitch.tv/nicetwice',
    youtube: 'https://www.youtube.com/channel/UCM0TWgE1b5H0QJ-7RDZpH5Q',
    twitter: 'https://twitter.com/NiceTwice_'
}, {
    name: 'LetsHugo',
    team: 1,
    avatar: getPlayerImage('hugo'),
    insta: '',
    twitch: 'https://twitch.tv/LetsHugoTV',
    youtube: 'https://www.youtube.com/c/LetsHUgo',
    twitter: 'https://twitter.com/LetsHugoYT'
}, {
    name: 'Dimeax',
    team: 1,
    avatar: getPlayerImage('dimeax'),
    insta: '',
    twitch: 'https://www.twitch.tv/Dimeax',
    youtube: 'https://www.youtube.com/channel/UCPrFGBRlTNcGkJl5-MXNpBg',
    twitter: 'https://twitter.com/Dimeax_'
}, {
    name: 'CastCrafter',
    team: 2,
    avatar: getPlayerImage('castcrafter'),
    insta: 'https://www.instagram.com/castagram_/',
    twitch: 'https://www.twitch.tv/castcrafter/',
    youtube: 'https://www.youtube.com/castcrafter',
    twitter: 'https://twitter.com/CastCrafter'
}, {
    name: 'Misfit',
    team: 2,
    avatar: getPlayerImage('misfit'),
    insta: '',
    twitch: 'https://www.twitch.tv/misfitthereal',
    youtube: 'https://www.youtube.com/channel/UC8hZZgNKbCFeFEA6ixNq06Q',
    twitter: 'https://twitter.com/misfitmcsr'
}, {
    name: 'Tjan',
    team: 3,
    avatar: getPlayerImage('tjan'),
    insta: '',
    twitch: 'https://www.twitch.tv/tjan',
    youtube: 'https://www.youtube.com/channel/UCDXuZ19TemXX0gI-4B9AkfQ',
    twitter: 'https://twitter.com/TjanTV'
}, {
    name: 'Chaosflo',
    team: 3,
    avatar: getPlayerImage('chaosflo'),
    insta: 'https://instagram.com/Chaosflo1337',
    twitch: '',
    youtube: 'https://youtube.com/Chaosflo44',
    twitter: 'https://twitter.com/Chaosflo44'
}, {
    name: 'Stegi',
    team: 4,
    avatar: getPlayerImage('stegi'),
    insta: 'https://instagram.com/stegi',
    twitch: 'https://twitch.tv/stegi',
    youtube: 'https://youtube.com/bystegi',
    twitter: 'https://twitter.com/bystegi'
}, {
    name: 'MultiPorl',
    team: 4,
    avatar: getPlayerImage('multiporl'),
    insta: '',
    twitch: 'https://www.twitch.tv/multiporl',
    youtube: '',
    twitter: ''
}];