import * as React from 'react';
import styles from './Navbar.module.css';
import Button from '../button/Button';
import {PeepoLogo} from '../PeepoLogo';
import {isEventLive} from '../../utils/timing';
import {NavLink} from 'react-router-dom';
import {HOME_ROUTE, INFORMATION_ROUTE, LAST_EVENT_ROUTE, LIVE_EVENT_ROUTE} from '../../router/routerUrls';
import {LIVE_LEADERBOARD_SECTION_ID} from '../../pages/live_event/leaderboard/LiveLeaderboardSection';

export const Navbar: React.FC = (props) => {

    const live = isEventLive();

    return (
        <div className={styles.fixedNavbar + ' page-wrapper'}>
            <NavLink to={HOME_ROUTE}><PeepoLogo className={styles.fixedNavbarLogo}/></NavLink>

            <ul className={styles.fixedNavbarItemContainer}>
                <li className={live ? `${styles.fixedNavbarItem} ${styles.live}` : styles.fixedNavbarItem}>
                    <NavLink to={LIVE_EVENT_ROUTE}
                             className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>
                        Live</NavLink>
                </li>
                <li className={styles.fixedNavbarItem}>
                    <NavLink to={INFORMATION_ROUTE}
                             className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>
                        Turnier Information
                    </NavLink>
                </li>
                <li className={styles.fixedNavbarItem}>
                    <NavLink to={LAST_EVENT_ROUTE}
                             className={(navData) => navData.isActive ? `${styles.navbarLink} ${styles.active}` : styles.navbarLink}>
                        Last Events
                    </NavLink>
                </li>
                <li className={styles.fixedNavbarItem}>
                    <NavLink to={LIVE_EVENT_ROUTE + `#${LIVE_LEADERBOARD_SECTION_ID}`} className={styles.navbarLink}>
                        <Button color={'secondary'}>Leaderboard</Button></NavLink>
                </li>
            </ul>
        </div>
    );
};