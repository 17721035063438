import React from 'react';
import styles from './ProgressBar.module.css';

interface ProgressBarProps {
    height?: number;
    progress: number;
    className?: string; // Hier wird die className Prop hinzugefügt
}

export const ProgressBar: React.FC<ProgressBarProps> = ({height = 5, progress, className}) => {
    // Component implementation

    const dots = Array.from({ length: 5 }, (_, index) => ({
        id: index,
        filled: (index) * 0.25 <= progress,
    }));

    return (
        <div className={`${styles.container} ${className}`} style={{['--bar-height' as any]: `5px`}}>
            <div className={styles.progressBar} style={{['--progress' as any]: progress}}/>
            <div className={styles.pointContainer}>
                {dots.map((dot) => (
                    <div key={dot.id} className={`${styles.point} ${dot.filled ? styles.reached : ''}`}/>
                ))}
            </div>

        </div>
    );
};
