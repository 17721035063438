import * as React from 'react';
import {Paper} from '../../../../../components/paper/Paper';
import {RbSlide} from './slides/RbSlide';

export const HomeSlider: React.FC = (props) => {
    return (
        <Paper classname={`page-wrapper`} color={'#0E121A'} lightColor={'#253042'} pattern={false} >
            <RbSlide/>
        </Paper>
    );
};