export enum Checkpoint {
    START = 'START', NETHER_IN = 'NETHER_IN', END_IN = 'END_IN', FINISHED = 'FINISHED', SURRENDERED = 'SURRENDERED'
}

export enum ProgressValues {
    BLAZE_ROD = 'BLAZE_ROD', ENDER_PERL = 'ENDER_PERL', ENDER_EYE = 'ENDER_EYE', DRAGON_HEALTH = 'DRAGON_HEALTH'
}

export enum StatType {
    DEATHS = 'DEATHS',
    DAMAGE_TAKEN = 'DAMAGE_TAKEN',
    START = 'START',
    NETHER_IN = 'NETHER_IN',
    END_IN = 'END_IN',
    FINISHED = 'FINISHED',
    SURRENDERED = 'SURRENDERED'
}

export enum LeaderboardType {
    TEAM = 'team', PLAYERS = 'players'
}