export function isEventLive(): boolean {
    const currentTime = new Date();
    const targetDateStart = new Date('2023-06-22T16:00:00');
    const targetDateEnd = new Date('2023-06-25T20:00:00');

    return currentTime >= targetDateStart && currentTime <= targetDateEnd;
}

export function getRemainingTime(targetDate: Date) {
    const currentTime = new Date().getTime();
    const difference = targetDate.getTime() - currentTime;

    if (difference <= 0) {
        // Target date has already been reached
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
        days,
        hours,
        minutes,
        seconds,
    };
}

export function getTime(ticks: number){
    if (ticks === -1 || ticks === 0) {
        return '-';
    }
    const secondsTotal = ticks / 20;
    const secondsInt = Math.floor(secondsTotal % 60);
    const secondsDecimals = ((secondsTotal % 60) - secondsInt).toFixed(2).slice(2);
    const minutes = Math.floor(secondsTotal / 60 % 60);
    return `${toDigitString(minutes)}:${toDigitString(secondsInt)}.${secondsDecimals}`;
}

function toDigitString(x: number | string) {
    return String(x).padStart(2, '0');
}
