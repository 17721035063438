import * as React from 'react';
import styles from './HomeLeaderboardSection.module.css';
import {NavLink} from 'react-router-dom';
import {INFORMATION_ROUTE, LIVE_EVENT_ROUTE} from '../../../../router/routerUrls';
import Button from '../../../../components/button/Button';
import {MonsterPaper} from '../../../../components/paper/MonsterPaper';
import {IconText} from './IconText';
import ArrowRight from '../../../../assets/website/icons/arrow_right.svg';
import {LIVE_LEADERBOARD_SECTION_ID} from '../../../live_event/leaderboard/LiveLeaderboardSection';
import {GlobalLeaderboard} from './components/GlobalLeaderboard';

export const HomeLeaderboardSection: React.FC = (props) => {
    return (
        <div className={`section ${styles.leaderBoardSection}`}>
            <div className={'page-wrapper center'}>
                <h2 className={styles.headingText}>Leaderboard</h2>
                <GlobalLeaderboard/>
                <div className={styles.buttonContainer}>
                    <NavLink to={LIVE_EVENT_ROUTE + `#${LIVE_LEADERBOARD_SECTION_ID}`}>
                        <Button color={'secondary'} className={styles.btnPadding}>Maps</Button>
                    </NavLink>
                </div>

                <div className={styles.monsterContainer}>
                    <MonsterPaper>
                        <div className={styles.monsterContentContainer}>
                            <div>
                                <p>
                                    Hier gelangst du zum Live Event mit Stream, Leaderboard und Teilnehmerübersicht.
                                </p>
                                <IconText iconPath={ArrowRight} text={'LIVE EVENT'} route={LIVE_EVENT_ROUTE}/>
                            </div>

                            <div>
                                <p>
                                    Weitere Informationen zum Turnier und den Regeln findest du hier.
                                </p>
                                <IconText iconPath={ArrowRight} text={'FAQ'} route={INFORMATION_ROUTE}/>
                            </div>
                        </div>
                    </MonsterPaper>
                </div>
            </div>

        </div>
    );
};