import * as React from 'react';
import styles from './IntroSection.module.css';
import {IntroductionPaper} from './component/IntroductionPaper';

export const IntroSection: React.FC = (props) => {
    return (
        <div className={`section ${styles.welcomeContainer}`}>
            <div className={'page-wrapper'}>
                <h1>Turnier information</h1>
                <p>Das peepoSprint Speedrun Invitational als erstes Turnier seiner<br/> Art im deutschsprachigen Raum</p>

                <div className={styles.introductionPaper}>
                    <IntroductionPaper/>
                </div>

            </div>
        </div>
    );
};