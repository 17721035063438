import * as React from 'react';
import styles from './TextInput.module.css';

interface TextInputProps {
    name: string;
    type?: 'text' | 'email' | 'url';
    placeholder?: string;
    maxLength?: number;
    minLength?: number;
    required?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
                                                        name,
                                                        type = 'text',
                                                        placeholder,
                                                        maxLength,
                                                        minLength,
                                                        required = false,
                                                    }) => {
    return (
        <input
            name={name}
            type={type}
            className={styles.input}
            placeholder={placeholder}
            maxLength={maxLength}
            minLength={minLength}
            required={required}
        />
    );
};
