import React from 'react';
import styles from './TopAvatar.module.css';
import {Avatar} from './avatar/Avatar';
import UpRankImg from '../../../../../assets/website/icons/arrow_up.svg';
import DownRankImg from '../../../../../assets/website/icons/arrow_down.svg';
import {getPlayerImage, getTeam} from '../../../../../utils/firebase_consts';
import {getTime} from '../../../../../utils/timing';

interface TopAvatarProps {
    rank: number;
    icon?: string;
    name?: string;
    team: number;
    time: number;
    points: number;
    progress?: number;
    rankChange?: number;
    size?: number;
}

export const TopAvatar: React.FC<TopAvatarProps> = ({ rank, icon, name, team, time, points, progress, rankChange = 0, size=140 }) => {

    const hasIcon = rankChange !== 0;


    const teamData = getTeam(team);
    const timeString = time > 1 ? getTime(time) : '-';
    const pointsString = String(points).padStart(2, '0');

    const getRankText = (rank: number) => {
        switch (rank) {
            case 1:
                return '1ST';
            case 2:
                return '2ND';
            case 3:
                return '3RD';
            default:
                return `${rank}TH`;
        }
    };

    const getRankColor = (rank: number) => {
        switch (rank) {
            case 1:
                return '#23F6CD';
            case 2:
                return '#00AE7B';
            case 3:
                return '#4D0A3B';
            default:
                return `#0055ff`;
        }
    };

    const chooseImage = () => {
        const icon = rankChange < 0 ? DownRankImg : UpRankImg;
        const altText = rankChange < 0 ? 'Arrow down for down-rank symbol' : 'Arrow up for up-rank symbol';
        return <img src={icon} className={styles.rankIcon} alt={altText} />;
    }

    const avatarContainerStyle = {
        '--border-color': getRankColor(rank), // Default border color
    } as React.CSSProperties;


    return (
        <div className={styles.topAvatar}>
            <div className={styles.rankContainer}>
                {/*{hasIcon && chooseImage()}*/}
                <h3 className={styles.rankText}>{getRankText(rank)}</h3>
            </div>
            <div className={`${styles.avatarContainer} ${rank === 1 ? styles.starBackground : undefined}`} style={avatarContainerStyle}>
                <Avatar  size={size} image1={getPlayerImage(teamData.members[0])} image2={getPlayerImage(teamData.members[1])}/>
            </div>
            <div className={styles.infoContainer}>
                <h4 className={styles.name} style={{color: teamData.color}}>{teamData.name}</h4>
                {/*<p className={styles.team}>Team</p>*/}
                <p className={styles.time}>{timeString}</p>
                <p className={styles.points}>{pointsString} P</p>
            </div>
        </div>
    );
};
