import React, { createContext, useContext, useState } from 'react';

interface TabsContextValue {
    value: any;
    changeTab: (tab: any) => void;
    metaData: any;
    setMetaData: (metaData: any) => void;
}

const TabsContext = createContext<TabsContextValue | undefined>(undefined);

interface TabsProviderProps {
    children: React.ReactNode;
    defaultVal?: any;
    defaultMeta?: any;
}

export const TabsProvider: React.FC<TabsProviderProps> = ({ children, defaultVal, defaultMeta }) => {
    const [activeTab, setActiveTab] = useState<any>(defaultVal);
    const [metaData, setMetaData] = useState<any>(defaultMeta);

    const changeTab = (tab: any) => {
        setActiveTab(tab);
    };

    const value: TabsContextValue = {
        value: activeTab,
        changeTab,
        metaData,
        setMetaData
    };

    return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

export const useTabs = (): TabsContextValue => {
    const context = useContext(TabsContext);
    if (!context) {
        throw new Error('useTabs must be used within a TabsProvider');
    }
    return context;
};
