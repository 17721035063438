import * as React from 'react';
import styles from './MapTeamLeaderboardRow.module.css';
import {TeamLeaderboardEntry} from '../../../../../types/leaderboard';
import {getTeam} from '../../../../../utils/firebase_consts';
import {getTime} from '../../../../../utils/timing';

interface TeamleaderboardRowProps {
    row: TeamLeaderboardEntry;
}

export const TeamLeaderboardRow: React.FC<TeamleaderboardRowProps> = ({row}) => {
    const {rank, team, ticks, rankingPoints} = row;
    const color = rank % 2 === 0 ? 'transparent' : 'var(--secondary-dark)';
    const timeString = ticks > 1 ? getTime(ticks) : '-';
    const teamString = getTeam(team).name;
    const points = String(rankingPoints?.toFixed(2)).padStart(2, '0');


    return (
        <div className={styles.mapLeaderboardRow} style={{['--row-color' as any]: color}}>
            <span className={styles.rank}>{rank}</span>
            <span className={styles.team}>{teamString}</span>
            <span className={styles.placeholder}></span>
            <span className={styles.time}>{timeString}</span>
            <span className={styles.points}>{points}P</span>
        </div>
    );
};