import React from 'react';
import styles from './PlayerLeaderboardRow.module.css';
import {PlayerLeaderboardEntry} from '../../../../../types/leaderboard';
import {getTime} from '../../../../../utils/timing';
import {getTeam} from '../../../../../utils/firebase_consts';

interface PlayerLeaderboardRowProps {
   row: PlayerLeaderboardEntry
}

export const PlayerLeaderboardRow: React.FC<PlayerLeaderboardRowProps> = ({row}) => {
    const {rank, playerName, team, ticks, rankingPoints} = row;
    const color = rank % 2 === 0 ? 'transparent' : 'var(--secondary-dark)';
    const timeString = ticks > 1 ? getTime(ticks) : '-';
    const teamString = getTeam(team).name;
    const points = String(rankingPoints).padStart(2, '0');
    return (
        <div className={styles.mapLeaderboardRow} style={{['--row-color' as any]: color}}>
            <span className={styles.rank}>{rank}</span>
            <span className={styles.name}>{playerName}</span>
            <span className={styles.team}>{teamString}</span>
            <span className={styles.placeholder}></span>
            <span className={styles.time}>{timeString}</span>
            <span className={styles.points}>{points}P</span>
        </div>
    );
};
