import React from 'react';
import styles from './SmallNavbar.module.css';
import {PeepoLogo} from '../../../components/PeepoLogo';
import {NavLink} from 'react-router-dom';
import {HOME_ROUTE} from '../../../router/routerUrls';

interface SmallNavbarProps {
    // Props definition
}

export const SmallNavbar: React.FC<SmallNavbarProps> = ({}) => {
    // Component implementation
    return (
        <div className={styles.container}>
            <NavLink to={HOME_ROUTE} style={{cursor: 'pointer'}}>
                <PeepoLogo/>
            </NavLink>

        </div>
    );
};
