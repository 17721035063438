import * as React from 'react';
import {TabsProvider} from '../../../components/tabs/TabsProvider';
import {RulesTabs} from './components/RulesTabs';
import {RULE_TYPES} from './rule_data';
import styles from './RulesSection.module.css';

export const RulesSection: React.FC = (props) => {
    const defaultVal =  RULE_TYPES.length ? RULE_TYPES[0].value : undefined;

    return (
        <>
            <div className={`section ${styles.rulesSection}`}>
                <div className={'page-wrapper center'}>
                    <h2>Rules</h2>
                    <p className={styles.rulesSubtitle}>Alle Regeln sind hier einsehbar. Bei Fragen wenden Sie sich bitte an die Organisation.</p>
                   <div className={styles.rulesContent}>
                       <TabsProvider defaultVal={defaultVal}>
                           <RulesTabs />
                       </TabsProvider>
                   </div>
                </div>
            </div>
        </>

    );
};