import * as React from 'react';
import styles from './PlayerSlide.module.css';
import {useState} from 'react';
import TwitterLogo from '../../../assets/website/brands/twitter_logo.svg';
import TwitchLogo from '../../../assets/website/brands/twitch_logo.svg';
import YoutubeLogo from '../../../assets/website/brands/youtube_logo.svg';
import {TEAM_DATA} from '../../../utils/firebase_consts';
//https://github.com/AaronCCWong/react-card-flip/blob/master/src/ReactCardFlip.tsx

type PlayerSlideProps = {
    data: {
        name: string
        team: number;
        avatar: string
        twitch?: string;
        insta?: string;
        twitter?: string;
        youtube?: string;
    };
}

export const PlayerSlide: React.FC<PlayerSlideProps> = ({data}) => {
    const {name, team, avatar, twitch, youtube, insta, twitter} = data;
    const [showBack, setShowBack] = useState(false);
    const teamData = TEAM_DATA[team];
    const handleClickFlipOver = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setShowBack(!showBack);
    }

    const handleLinkClick = (link: string) => (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        window.open(link, '_blank');
    };

    return (
        <div className={styles.playerCardContainer}>
            <div className={`${styles.cardFrontContent} ` + (!showBack ? styles.active : '')}>
                <div className={styles.playerTopControls}>
                    <div className={styles.playerSocialsContainer}>
                        {twitter && <img src={TwitterLogo} alt={'Twitter symbol on click open channel'}
                              onClick={handleLinkClick(twitter)}/>}
                        {twitch && <img src={TwitchLogo} alt={'Twitch symbol on click open channel'}
                              onClick={handleLinkClick(twitch)}/>}
                        {youtube && <img src={YoutubeLogo} alt={'Youtube symbol on click open channel'}
                              onClick={handleLinkClick(youtube)}/>}
                    </div>
                    {/*<img className={styles.flipArrow} src={ArrowFlip} alt={'arrow to turn to back side'} onClick={handleClickFlipOver}/>*/}
                </div>
                <img src={avatar} className={styles.avatar}  alt={`Profile of ${name}`}/>
                <div className={styles.playerInfoContainer}>
                    <span className={styles.playerTag}>{name}</span>
                    <span className={styles.teamTag} style={{color: teamData.color}}>{teamData.name}</span>
                </div>
            </div>
            <div className={`${styles.cardBackContent} ` + (showBack ? styles.active : '')}>
                <span>Player</span>
                <span>Korem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a,
                    mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                    interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class
                    aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos</span>
            </div>
        </div>
    );
};