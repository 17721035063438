export const FAQ_DATA = [{
    title: 'Woher kommt die Idee?',
    description: 'Wir hatten 2021 die Idee Speedrunning für die breite Masse zugänglicher zu machen. In diesem zug haben wir ' +
        'uns dazu entschlossen peepoSprint V1 zu veranstalten.'
}, {
    title: 'Was sind Tag-Teams',
    description: 'Um den educational Faktor des Turniers zu erhöhen, haben wir uns dazu entschlossen, dass die Teams aus ' +
        'einem Pro und einem Streamer bestehen. So wollen wir Entertainment und Professionalität vereinen.'
}, {
    title: 'Wer steck hinter peepoSprint',
    description: 'PeepoSprint wird von diversen Personen organisiert. Ganz vorne mit dabei sind: Veni aka Rafi, Dweags aka Maxi und das Studio team'
}, {
    title: 'Werden andere Spiele gespielt?',
    description: 'Initial bestand die Idee darin peepoSprint unabhängig von Minecraft zu gestalten. Dies haben wir auch weiterhin beibehalten' +
        'und werden in Zukunft vielleicht auch andere Spiele in das Turnier einbinden.'
}, {
    title: 'Wie kann ich mitmachen?',
    description: 'Momentan ist peepoSprint noch ein geschlossenes Turnier. Wir planen aber in Zukunft etwas großes also seid' +
        'gespannt und eventuell bekommt auch ihr die Chance zu Glänzen'
}]