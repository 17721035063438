import * as React from 'react';
import {PartnerContainer} from '../../components/partners/PartnerContainer';
import styles from './LastEventPage.module.css';

export const LastEventsPage: React.FC = (props) => {
    return (
        <React.Fragment>
            <div className={styles.gradientBackground}>
                <div className={`page-wrapper center`}>
                    <div className={styles.container}>
                        <div>
                            <h1>Last Events</h1>
                            <p>Das peepoSprint Speedrun Invitational als erstes Turnier seiner<br/> Art im deutschsprachigen
                                Raum</p>
                        </div>
                        <span className={styles.comingSoon}>COMING SOON</span>
                    </div>
                </div>
                <PartnerContainer/>
            </div>

        </React.Fragment>
    );
};