import * as React from 'react';
import {Route, Routes} from 'react-router-dom';
import {
    HOME_ROUTE,
    IMPRINT_ROUTE, INFORMATION_ROUTE,
    LAST_EVENT_ROUTE,
    LIVE_EVENT_ROUTE,
    PREVIEW_ROUTE,
    PRIVACY_ROUTE
} from './routerUrls';
import {HomePage} from '../pages/home/HomePage';
import {LastEventsPage} from '../pages/last_events/LastEventsPage';
import {LiveEventPage} from '../pages/live_event/LiveEventPage';
import {Imprint} from '../pages/utility/Imprint';
import {Privacy} from '../pages/utility/Privacy';
import {PreviewPage} from '../pages/preview/PreviewPage';
import {InformationPage} from '../pages/information/InformationPage';
import {useIsSmallScreen} from '../hooks/useIsSmallScreen';
import {SmallScreenPage} from '../pages/screen/small/SmallScreenPage';


export const PeepoRoutes: React.FC = (props) => {
    const {isSmallScreen} = useIsSmallScreen();
    return (
        <Routes>
            {isSmallScreen && <>
                <Route path={HOME_ROUTE} element={<SmallScreenPage/>}/>
            </>}
            <Route path={IMPRINT_ROUTE} element={<Imprint/>}/>
            <Route path={PRIVACY_ROUTE} element={<Privacy/>}/>
            {!isSmallScreen && <>
                <Route path={LIVE_EVENT_ROUTE} element={<LiveEventPage/>}/>
                <Route path={INFORMATION_ROUTE} element={<InformationPage/>}/>
                <Route path={LAST_EVENT_ROUTE} element={<LastEventsPage/>}/>
                <Route path={HOME_ROUTE} element={<HomePage/>}/>
            </>}
            <Route path={PREVIEW_ROUTE} element={<PreviewPage/>}/>
        </Routes>
    );
};
