import React from 'react';
import { useTabs } from './TabsProvider';
import styles from './Tab.module.css';

interface TabProps {
    children: React.ReactNode;
    value: any;
    className?: string;
}

export const Tab: React.FC<TabProps> = ({ children, value, className }) => {
    const { value: activeTab } = useTabs();

    const isActive = activeTab === value;
    return (
        <div className={isActive ? className : `${className} ${styles.hiddenTab}`}>
            {isActive && children}
        </div>
    );
};
