import React from 'react';
import styles from './SmallScreenSection.module.css';
import {TwitchStream} from '../../../../components/twitch/TwitchStream';
import {TwitchPlayer, TwitchPlayerNonInteractive} from 'react-twitch-embed';

interface SmallStreamSectionProps {
    // Props definition
}

export const SmallStreamSection: React.FC<SmallStreamSectionProps> = ({}) => {


    return (
        <div className={styles.container}>
            <TwitchPlayerNonInteractive
                channel={'veni'}
                autoplay={true}
                muted={false}
                width={'100%'}
                height={'100%'}
            />
        </div>
    );
};
