import * as React from 'react';
import {useEffect, useState} from 'react';
import {fetchLeaderboardDoc} from '../../../../../firebase/firebase';
import {TopAvatar} from './TopAvatar';
import styles from './GlobalLeaderboard.module.css';
import {GlobalLeaderboardRow} from './GlobalLeaderboardRow';
import {TeamLeaderboard} from '../../../../../types/leaderboard';

export const GlobalLeaderboard: React.FC = (props) => {

    const [leaderboardData, setLeaderboardData] = useState<TeamLeaderboard>();

    useEffect(() => {
        let isMounted = true; // Flag to track if the component is still mounted

        async function fetchLeaderboard() {
            try {
                const globalTeamLeaderboard = await fetchLeaderboardDoc<TeamLeaderboard>('team_global');
                if (isMounted) {
                    setLeaderboardData(globalTeamLeaderboard);
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchLeaderboard().then(r => console.log('fetched leaderboard'));

        // Cleanup function
        return () => {
            isMounted = false; // Set the flag to false when the component is unmounted
        };
    }, []);
    const first = leaderboardData?.results?.find((entry) => entry.rank === 1);
    const second = leaderboardData?.results?.find((entry) => entry.rank === 2);
    const third = leaderboardData?.results?.find((entry) => entry.rank === 3);

    return (
        <div>
            <div className={styles.avatarContainer}>
                {second && <TopAvatar rank={2} team={second.team} size={100} time={second.ticks} points={second.rankingPoints} />}
                {first && <TopAvatar rank={1} team={first.team} size={140} time={first.ticks} points={first.rankingPoints}/>}
                {third && <TopAvatar rank={3} team={third.team} size={100} time={third.ticks} points={third.rankingPoints}/>}
            </div>
            <div className={styles.rowsContainer}>
                {leaderboardData && leaderboardData.results.map((entry) => (
                    <GlobalLeaderboardRow key={entry.team} data={entry}/>
                ))}
                {!leaderboardData && (
                    <h4 style={{textAlign: 'center'}}>Waiting for data</h4>
                )}
            </div>

        </div>
    );
};